import React, { useState, useEffect } from "react"
import Clock from "react-clock"
const TimeMexJp = () => {
  const eventJapan = new Date()
  const eventMexico = new Date()

  var timeJapan = eventJapan.toLocaleTimeString("es-MX", { timeZone: "Japan" })

  var timeMexico = eventMexico.toLocaleTimeString("es-MX", {
    timeZone: "America/Mexico_City",
  })

  const [valueJapan, setValueJapan] = useState(new Date())
  const [valueMexico, setValueMexico] = useState(new Date())

  var textTimeJapan = eventJapan.toLocaleTimeString("es-MX", {
    timeZone: "Japan",
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  })

  var textTimeMexico = eventMexico.toLocaleTimeString("es-MX", {
    timeZone: "America/Mexico_City",
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  })

  useEffect(() => {
    const intervalJapan = setInterval(() => setValueJapan(timeJapan), 1000)
    return () => {
      clearInterval(intervalJapan)
    }
  }, [valueJapan, setValueJapan,timeJapan])
  useEffect(() => {
    const intervalMexico = setInterval(() => setValueMexico(timeMexico), 1000)
    return () => {
      clearInterval(intervalMexico)
    }
  }, [valueMexico, setValueMexico,timeMexico])

  return (
    <>
      <div className="hmenu_sec3_time">
        <Clock
          size={20}
          value={valueJapan}
          hourMarksWidth={1}
          hourHandWidth={1.5}
          minuteHandWidth={1}
          renderNumbers={false}
          minuteMarksLength={5}
          hourMarksLength={12.7}
          renderSecondHand={false}
           
        />
          <p>
            {"JAPAN "}
            <span>{textTimeJapan.replace(/\s+/g, "").replace(/\.+/g, "").toUpperCase()}</span>
            {" / MEXICO "}
            <span>
              {textTimeMexico.replace(/\s+/g, "").replace(/\.+/g, "").toUpperCase()}
            </span>
          </p>
        </div>
    </>
  )
}

export default TimeMexJp
