import React from "react"
const LoaderEn = () => {
  return (
    <>
    <div className="content-loader-en">
      <p>Loading</p>
      <div className="loader-en"></div>
    </div>
    </>
  )
}

export default LoaderEn
