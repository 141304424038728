import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import fetch from "cross-fetch"
const URL_PROD = "https://admin-919mexico.herokuapp.com/graphql"
//const URL_LOCAL = "http://localhost:5432/graphql"
const httpLink = createHttpLink({
  uri: URL_PROD,
  fetch,
})

const client = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache(),
  link: httpLink,
})

export default client
