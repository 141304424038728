import React from "react"
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
const FooterEn = () => {
  // Images
  const logoMexico = require("../../images/919_mexico_logo_en.svg")
  const logoCrossBorder = require("../../images/cross-border.svg")
  const jpFlag = require("../../images/footer/circle-jp-flag.svg")
  const chFlag = require("../../images/footer/circle-ch-flag.svg")
  return (
    <div className="footer-en">
      <div className="container">
        <div className="footer-style_logos">
          <img src={logoMexico} alt="logo-mexico" className="mexico" />
        </div>
        <div className="row two-columns">
          <div className="sec-footer-en">
            <p className="footer-store-name">
              Aguascalientes Office (Head office)
            </p>
            <table>
              <tr>
                <td>Address</td>
                <td>
                  C.C. Punto 45, Boulevard A Zacatecas 845, Oficina 510,
                  Desarrollo Especial Bulevar a Zacatecas, C.P.20116
                  Aguascalientes, Ags.
                </td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>+52-449-153-1400</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>quick@919mexico.com</td>
              </tr>
            </table>
          </div>
          <div className="sec-footer-en">
            <p className="footer-store-name">Querétaro Office</p>
            <table>
              <tr>
                <td>Address</td>
                <td>
                  Av. Armando Birlain 2001 Corporativo 1 Piso 15A, Colonia
                  Centro Sur (Complejo Central Park), Santiago de Querétaro,
                  Qro. C.P 76090
                </td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>+52-449-111-8229</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>quick@919mexico.com</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="sec2-footer-en">
          <img
            src={logoCrossBorder}
            alt="logo-mexico"
            className="cross-border-en"
          />
          <div className="sec2-footer-en_social-media">
            <div className="container-social">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/quickglobalmexico/"
                aria-label="Instagram"
              >
                <FaInstagram size={17} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/quickmexico"
                aria-label="Twitter"
              >
                <FaXTwitter size={17} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/919mexico/"
                aria-label="Facebook"
              >
                <FaFacebookF size={17} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/919mexico/"
                aria-label="Linkedin"
              >
                <FaLinkedinIn size={17} />
              </a>
            </div>
            <div className="container-flag">
              <a
                href="https://919mexico.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={jpFlag} alt="us-flag" />
              </a>
              <a
                href="https://919mexico-ch.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={chFlag} alt="china-flag" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p className="container">
          Copyright © QUICK GLOBAL MEXICO, S.A. DE C.V. All Rights Reserved.
        </p>
      </div>
    </div>
  )
}

export default FooterEn
