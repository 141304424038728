import React, { createContext, useState } from "react"

export const SearchJobContext = createContext(null)

const SearchJobProvider = props => {
  const [mark, setMark] = useState([])
  const [tagLocation, setTagLocation] = useState([])
  const [tagOnly, setTagOnly] = useState([])
  const [allTag, setAllTag] = useState("")
  const [dataJobSearch, setdataJobSearch] = useState([])
  return (
    <SearchJobContext.Provider
      value={{
        mark,
        setMark,
        tagLocation,
        setTagLocation,
        tagOnly,
        setTagOnly,
        allTag,
        setAllTag,
        dataJobSearch,
        setdataJobSearch,
      }}
    >
      {props.children}
    </SearchJobContext.Provider>
  )
}

export default SearchJobProvider
