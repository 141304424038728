import React, { createContext, useState } from "react"

export const SearchContext = createContext(null)

const SearchProvider = props => {
  const [text, setText] = useState("")
  const [type, setType] = useState("")
  const [dataSearch, setDataSearch] = useState([])
  return (
    <SearchContext.Provider
      value={{ text, setText, type, setType, dataSearch, setDataSearch }}
    >
      {props.children}
    </SearchContext.Provider>
  )
}

export default SearchProvider
