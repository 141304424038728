import React from "react"
import SearchContext from "../context/SearchContext"
import SearchJobContext from "../context/SearchJobContext"

import client from "../apollo/client"
import { ApolloProvider } from "@apollo/client"
import { ToastContainer, toast } from "react-toastify"
import Layout from "../components/layouts/Layout"

import "react-toastify/dist/ReactToastify.css"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <ToastContainer
      draggable={false}
      autoClose={4000}
      pauseOnHover={false}
      position={toast.POSITION.TOP_CENTER}
    />
    <SearchContext>
      <SearchJobContext>
            <Layout>{element}</Layout>
      </SearchJobContext>
    </SearchContext>
  </ApolloProvider>
)
